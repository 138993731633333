<template>
    <slim-layout>
        <div class="learn">
            <h1>Learn</h1>
            <h3>ELI5</h3>
            <p class="first-paragraph">
                E-Props is a first of its kind platform focused on genuinely programmable on demand 
                generative content that is stored immutably on the Secret Network Blockchain. You pick a style 
                that you like, pay for the work, and a randomly generated version of the content is created 
                by an algorithm and sent to your Secret Network account. The resulting piece might be a static 
                image, 3D model, or an interactive experience. Each output is different and there are 
                endless possibilities for the types of content that can be created on the platform.
            </p>
            <h3>How to Mint</h3>
            <p>
                If you enjoy the style of a work that is hosted on the E-Props platform you can mint a 
                unique iteration for yourself. The work is minted/generated/created on demand so you 
                don't actually know what the specific piece you are getting will look like!
            </p>
            <p>
                To mint, visit a project while connected to Keplr. If you do not already have Keplr 
                installed you'll need to visit <a href="https://wallet.keplr.app/" target="_blank">https://wallet.keplr.app/</a> to install the extension in your browser.
            </p>
            <p>
                Once you're connected, you'll see a purchase button in the description area for each 
                project. Note that each project has a set maximum iteration limit and if that limit is reached 
                you will be unable to mint a new work!
            </p>
            <p>
                When you click the purchase button you'll be presented with a Keplr window for 
                confirming the transaction. It will show the price for the mint as well as the gas cost for 
                executing the transaction. Click "Confirm" to initiate the purchase transaction.
            </p>
            <p>
                After the transaction is confirmed you will be presented with your generative content! This is a 
                one of a kind iteration generated by the project's algorithm. The resulting work is represented on 
                the Secret Network network by a token which is a standard ERC721 compatible NFT token. This means 
                you will instantly be able to see it in your account on various marketplace platforms like OpenSea.
            </p>
            <p>
                Note: If the Secret Network blockchain is backlogged and your token takes too long to mint, you might 
                not be presented with the token image automatically upon minting it. The token will appear in your 
                "my items" page once the transaction is confirmed.
            </p>
        </div>
    </slim-layout>
</template>

<script>
import SlimLayout from "../layouts/SlimLayout.vue";

export default {
    name: 'learn',
    components: { SlimLayout },
    mounted() {
        window.scrollTo({ top: 0});
    }
}
</script>

<style lang="scss" scoped>
.learn {
    margin-left: 11px;
    padding-top: 34px;
    h1 {
        margin: 8px 0px;
        letter-spacing: 0.01em;
        line-height: 46px;
        font-size: 38px;
    }
    h3 {
        padding-top: 18px;
        padding-bottom: 0px;
        margin: 8px 0px;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.01em;
    }
    p {
        margin-bottom: 23px;
        color: #808080;
        a { 
            color: inherit; 
        } 
    }
    .first-paragraph {
        margin: 0px;
    }
    @include respond-to("medium and down") {
        padding-top: 9px;
        margin-left: 0px;
        h3 {
            padding-top: 18px;
            padding-bottom: 0px;
        }
    }
}
</style>