<template>
    <column mode="slim">
        <block>
          <slot/>
        </block>
    </column>
</template>

<script>
export default {

}
</script>

<style>

</style>